'use client';
import { Transition, Typography } from 'components';
import { SUPPORT_LINK } from 'constants/links';
import Link from 'next/link';
import 'styles/sass/not-found.scss';

export default function NotFound() {
  return (
    <div>
      <div className="flex h-[100vh] w-full flex-1 flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <Transition className="h-full">
            <div className="flex flex-col items-center gap-8">
              <Typography className="!text-[5rem] text-tertiary-200" variant="regular7xl">
                <b>Oops!</b>
              </Typography>
              <Typography className="!text-[2.5rem] text-tertiary-400" variant="light4xl">
                This page doesn&apos;t exist
              </Typography>
            </div>
          </Transition>
          <div className="mt-10 w-full">
            <section className="error-container">
              <span className="four"></span>
              <span className="zero"></span>
              <span className="four"></span>
            </section>
          </div>
          <Transition delay={0.4} className="h-full">
            <div className="mt-10 flex flex-col items-center">
              <div>
                <Typography variant="light3" className="text-tertiary-200">
                  The page you were looking for does not exist. You can try to{' '}
                  <Link className="cursor-pointer  text-secondary-link" href="/">
                    go back
                  </Link>{' '}
                  or continue to{' '}
                  <Link className=" text-secondary-link" href="/">
                    Tolq Translators
                  </Link>
                  .{' '}
                </Typography>
              </div>
              <div>
                <Typography variant="light3" className="mt-5 text-tertiary-200">
                  If you have any questions, don&apos;t hesitate to contact us at:{' '}
                  <a className=" text-secondary-link" href={SUPPORT_LINK}>
                    customerservice@tolq.com
                  </a>
                </Typography>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}
